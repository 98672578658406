import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import "./layout.css"
import ParticleBG from "../particleBackground/particleBackground"
import staticBG from "../../images/Phresh Static bg.jpg"
import CookieConsent from "react-cookie-consent"

// Layout component can be used to give a page a uniform layout
const Layout = ({ children, scroll }) => {
  // Querying the site meta data for the site title to provide to the header component
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // Polyfilling smooth scrolling and ensuring that "window" is not undefined before doing so
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  /* Hold off on the location logic until the relevant images are uploaded 
    const getLocation = () => {
      fetch('https://extreme-ip-lookup.com/json/')
        .then(res => res.json())
        .then(response => {
  
          const { continent } = response
  
          if (continent !== state.location) {
            if (!state.manual) {
              if (continent === 'Europe') {
                dispatch({ type: 'LOCATION_EUROPE' })
                localStorage.setItem('location', 'Europe')
              } else {
                dispatch({ type: 'LOCATION_ROW' })
                localStorage.setItem('location', 'Rest of World')
              }
            }
          }
        })
        .catch((data, status) => {
          console.log('Request failed');
        })
    } */

  const { detect } = require("detect-browser")
  const browser = detect()

  const browserIsChrome = browser.name === "chrome" || browser.name === "crios"

  return (
    <div
      className="app-container"
      style={{
        display: "block",
        ...(scroll && {
          overflow: "auto",
        }),
      }}
    >
      {/* Adding particle background to this component as it is server side rendered and will not interfere with other logic this way */}
      {browserIsChrome ? (
        <ParticleBG />
      ) : (
        <img
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            minWidth: "100vw",
            minHeight: "100vh",
            zIndex: -1,
          }}
          alt="particle background"
          src={staticBG}
        />
      )}

      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

// Ensuring that children are provided when this component is consumed
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
