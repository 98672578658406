import React from 'react'
import Particles from 'react-particles-js'
import './particleBackground.css'

// Particle background component
const ParticleBG = () => {
    return (
        // App background wrapper
        <div className="app-background">
            {/* Wrapper for particles */}
            <div className="particle-container">
                {/* Blurred particles to simulate close particles of dust not in focus */}
                <div className="blurred-particles">
                    <Particles width='100%' height='100vh'
                        params={{
                            "particles": {
                                "number": {
                                    "value": 100,
                                    "density": {
                                        "enable": true,
                                        "value_area": 603
                                    }
                                },
                                "color": {
                                    "value": "#ffffff"
                                },
                                "shape": {
                                    "type": "circle",
                                    "stroke": {
                                        "width": 0,
                                        "color": "#ffffff"
                                    },
                                    "polygon": {
                                        "nb_sides": 5
                                    },
                                    "image": {
                                        "src": "img/github.svg",
                                        "width": 100,
                                        "height": 100
                                    }
                                },
                                "opacity": {
                                    "value": 0.15232414578222467,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 0.1,
                                        "opacity_min": 0.023976023976023976,
                                        "sync": false
                                    }
                                },
                                "size": {
                                    "value": 20,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 0.1,
                                        "size_min": 0,
                                        "sync": false
                                    }
                                },
                                "line_linked": {
                                    "enable": false,
                                    "distance": 150,
                                    "color": "#ffffff",
                                    "opacity": 0.4,
                                    "width": 1
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 1,
                                    "direction": "left",
                                    "random": true,
                                    "straight": false,
                                    "out_mode": "out",
                                    "bounce": false,
                                    "attract": {
                                        "enable": false,
                                        "rotateX": 600,
                                        "rotateY": 1200
                                    }
                                }
                            },
                            "interactivity": {
                                "detect_on": "canvas",
                                "events": {
                                    "onhover": {
                                        "enable": false,
                                        "mode": "repulse"
                                    },
                                    "onclick": {
                                        "enable": false,
                                        "mode": "push"
                                    },
                                    "resize": true
                                },
                                "modes": {
                                    "grab": {
                                        "distance": 400,
                                        "line_linked": {
                                            "opacity": 1
                                        }
                                    },
                                    "bubble": {
                                        "distance": 400,
                                        "size": 40,
                                        "duration": 2,
                                        "opacity": 8,
                                        "speed": 3
                                    },
                                    "repulse": {
                                        "distance": 200,
                                        "duration": 0.4
                                    },
                                    "push": {
                                        "particles_nb": 4
                                    },
                                    "remove": {
                                        "particles_nb": 2
                                    }
                                }
                            },
                            "retina_detect": true
                        }} />
                </div>

                {/* Normal paricles in focus */}
                <div>
                    <Particles width='100%' height='100vh'
                        params={{
                            "particles": {
                                "number": {
                                    "value": 210,
                                    "density": {
                                        "enable": true,
                                        "value_area": 603
                                    }
                                },
                                "color": {
                                    "value": "#ffffff"
                                },
                                "shape": {
                                    "type": "circle",
                                    "stroke": {
                                        "width": 0,
                                        "color": "#ffffff"
                                    },
                                    "polygon": {
                                        "nb_sides": 5
                                    },
                                    "image": {
                                        "src": "img/github.svg",
                                        "width": 100,
                                        "height": 100
                                    }
                                },
                                "opacity": {
                                    "value": 0.4,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 0.1,
                                        "opacity_min": 0.023976023976023976,
                                        "sync": false
                                    }
                                },
                                "size": {
                                    "value": 4,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 0.1,
                                        "size_min": 0,
                                        "sync": false
                                    }
                                },
                                "line_linked": {
                                    "enable": false,
                                    "distance": 150,
                                    "color": "#ffffff",
                                    "opacity": 0.4,
                                    "width": 1
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 0.5,
                                    "direction": "left",
                                    "random": true,
                                    "straight": false,
                                    "out_mode": "out",
                                    "bounce": false,
                                    "attract": {
                                        "enable": false,
                                        "rotateX": 600,
                                        "rotateY": 1200
                                    }
                                }
                            },
                            "interactivity": {
                                "detect_on": "canvas",
                                "events": {
                                    "onhover": {
                                        "enable": false,
                                        "mode": "repulse"
                                    },
                                    "onclick": {
                                        "enable": false,
                                        "mode": "push"
                                    },
                                    "resize": true
                                },
                                "modes": {
                                    "grab": {
                                        "distance": 400,
                                        "line_linked": {
                                            "opacity": 1
                                        }
                                    },
                                    "bubble": {
                                        "distance": 400,
                                        "size": 40,
                                        "duration": 2,
                                        "opacity": 8,
                                        "speed": 3
                                    },
                                    "repulse": {
                                        "distance": 200,
                                        "duration": 0.4
                                    },
                                    "push": {
                                        "particles_nb": 4
                                    },
                                    "remove": {
                                        "particles_nb": 2
                                    }
                                }
                            },
                            "retina_detect": true
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ParticleBG